import React, { useMemo } from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';

import {
  Container,
  CourseSection,
  MeetSection,
  CourseList,
  Course,
} from '../styles/cursos';

import GlobalStyle from '../styles/global';

export default function Courses({ data }) {
  const groups = useMemo(() => {
    const categories = data.categories.edges.map(category => category.node);
    const courses = data.courses.edges.map(({ course }) => course);

    return categories
      .map(category => ({
        category,
        courses: courses.filter(
          course => course.category.slug === category.slug
        ),
      }))
      .filter(group => group.courses.length > 0);
  }, [data]);

  function renderCourse(course) {
    return (
      <Course key={String(course.slug)}>
        <Link to={`/${course.category.slug}/${course.slug}`}>
          <h2 style={{ visibility: 'hidden', height: 0 }}>{course.title}</h2>
          <strong style={{ visibility: 'hidden', height: 0 }}>
            {course.description}
          </strong>
          <img src={course.picture.file.url} alt={course.title} />
        </Link>
      </Course>
    );
  }

  function renderCourseList(group) {
    return (
      <CourseList key={group.category.slug}>
        <h3>{group.category.label}</h3>
        <div>{group.courses.map(course => renderCourse(course))}</div>
      </CourseList>
    );
  }

  return (
    <>
      <Layout
        seoProps={{
          title: 'Cursos',
          pathname: `/cursos`,
        }}
        headerOptions={{
          fixed: true,
          hasOptions: true,
        }}
      >
        <Container>
          <CourseSection>
            <div>
              <h1>CURSOS</h1>
              <hr />
            </div>
          </CourseSection>
          <MeetSection>
            <div>
              <h2>CONHEÇA NOSSOS CURSOS</h2>
              <hr />
              <p>
                Aumente seu conhecimento nas diferentes áreas do marketing
                digital através dos nossos treinamentos!
              </p>
              <div>{groups.map(group => renderCourseList(group))}</div>
            </div>
          </MeetSection>
        </Container>
        <GlobalStyle />
      </Layout>
    </>
  );
}

export const getCourses = graphql`
  query {
    courses: allContentfulCourse {
      edges {
        course: node {
          category {
            slug
          }
          slug
          title
          picture {
            file {
              url
            }
          }
        }
      }
    }
    categories: allContentfulCourseCategory {
      edges {
        node {
          slug
          label
        }
      }
    }
  }
`;
