import styled from 'styled-components';

import responsive from '../../styles/responsive';
import colors from '../../styles/colors';

const background = '/images/background.svg';

export const Container = styled.div`
  ${responsive.query(responsive.desktopMin)} {
    margin-top: -60px;
  }
`;

export const CourseSection = styled.div`
  padding: 6% 5%;

  background-image: url(${background}), linear-gradient(to right, #191919, #000);
  background-repeat: no-repeat;
  background-size: cover;

  ${responsive.query(responsive.desktopMin)} {
    padding: 3% 5%;
  }

  & > div {
    margin: 0 auto;

    ${responsive.maxQuery(responsive.desktopMin)} {
      max-width: 460px;
    }

    h1 {
      margin-top: 20px;
      font-size: 2em;

      ${responsive.query(responsive.desktopMin)} {
        text-align: center;
        font-size: 3em;
      }
    }

    hr {
      margin: 1rem 0;
      border: 2px solid red;
      width: 40px;

      ${responsive.query(responsive.desktopMin)} {
        display: none;
      }
    }
  }
`;

export const MeetSection = styled.div`
  padding: 40px 5%;

  color: ${colors.blackText};
  background: #fff;

  & > div {
    margin: 0 auto;
    max-width: 1140px;

    h2 {
      font-size: 2em;
    }

    hr {
      margin: 1rem 0;
      border: 2px solid red;
      width: 40px;
    }

    p {
      margin-bottom: 30px;

      font-size: 16px;
      font-weight: 400;
      color: ${colors.grayText};
    }
  }
`;

const courseSize = '240px';
export const CourseList = styled.div`
  margin-bottom: 100px;

  &:first-child {
    margin-top: 60px;
  }

  &:not(:last-child) {
    margin-bottom: 100px;
  }

  h3 {
    margin-bottom: 12px;

    text-align: center;
    font-weight: bold;
    font-size: 24px;
  }

  & > div {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;

    ${responsive.query(responsive.tabletMin)} {
      grid-template-columns: repeat(2, ${courseSize});
    }

    ${responsive.query(responsive.desktopMin)} {
      grid-template-columns: repeat(3, ${courseSize});
    }

    ${responsive.query(responsive.fullMin)} {
      grid-template-columns: repeat(4, ${courseSize});
    }

    grid-gap: 20px;
    justify-items: center;
    justify-content: center;
  }
`;

export const Course = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  a {
    margin: 0 auto;
    align-self: center;

    img {
      border-radius: 10px;
      width: 100%;

      box-shadow: 0 5px 15px #ababab;
    }
  }
`;
